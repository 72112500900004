import React, { useState, useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "./index.css";
import search_icon from "../../images/search.png";
import location_icon from "../../images/search_location.png";
import home_dropdown from "../../images/home_dropdown.png";
import home_cal from "../../images/home_calander.png";
import { Button, Select, DatePicker } from "antd";
import moment from "moment";
import { falconApiCalling } from "../API/falconApiCalling";
export default function Index() {
  const [activity, setActivity] = useState([]);

  const getActivity = () => {
    let params = { action: "GET_CATEGORY_LIST" };
    falconApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        let arr = res.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setActivity(arr);
      }
    });
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const disabledDate = (currentDate) => {
    // Disable dates before today
    return currentDate && currentDate < moment().startOf("day");
  };

  useEffect(() => {
    getActivity();
  }, []);

  return (
    <Container>
      <Row>
        <Col md={3} className="location-box">
          <h1 className="location-box-heading">Location</h1>
          <Select
            className="home-drop-down"
            showSearch
            style={{
              width: 200,
            }}
            placeholder="Enter your destination"
            optionFilterProp="label"
            suffixIcon={
              <Image
                src={location_icon}
                style={{ width: "15px", height: "17px" }}
              />
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={[
              {
                value: "Dubai",
                label: "Dubai",
              },
              {
                value: "Abu Dhabi",
                label: "Abu Dhabi",
              },
            ]}
          />
        </Col>
        <Col md={3} className="activity-box">
          <h1 className="activity-box-heading">Activity</h1>
          <Select
            className="home-drop-down"
            showSearch
            style={{
              width: 200,
            }}
            placeholder="Enter your activity"
            optionFilterProp="label"
            suffixIcon={
              <Image
                src={home_dropdown}
                style={{ width: "18px", height: "18px" }}
              />
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={activity}
          />
        </Col>
        <Col md={3} className="date-box">
          <h1 className="date-box-heading">Date</h1>
          <DatePicker
            onChange={onChange}
            suffixIcon={
              <Image src={home_cal} style={{ width: "16px", height: "16px" }} />
            }
            disabledDate={disabledDate}
          />
        </Col>
        <Col md={3} className="search-button-box">
          <Button className="search-btn">
            <Image
              src={search_icon}
              className="search-icon"
              alt="search-icon"
            />
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
