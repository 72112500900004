import React from "react";
import { Container } from "react-bootstrap";
import { Button } from "antd";
import "./index.css";
export default function Index() {
  return (
    <div style={{ marginTop: "50px" }}>
      <Container>
        <div>
          <p className="ask_expert_text">
            If you have question about this tour, please feel free to ask
          </p>
          <Button className="ask_expert_btn">Ask the Tour Expert</Button>
          <p className="note_description" style={{ marginTop: "20px" }}>
            *All questions are replied to within 24-48 hrs
          </p>
        </div>
      </Container>
    </div>
  );
}
