import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, Form, Button, Image } from "react-bootstrap";
import logo from "../../images/logo.jpeg";
import { TiShoppingCart } from "react-icons/ti";
import { RiAccountCircleLine } from "react-icons/ri";

import "./index.css";
import { Badge } from "antd";
export default function Index() {
  const [cartNum, setCartNum] = useState(0);
  const [session, setSession] = useState(false);
  const [name, setName] = useState("");
  const getCart = () => {
    let product = localStorage.getItem("falcon_cart");
    let user_session = localStorage.getItem("falcon_user");
    if (product === null) {
      console.log("yes", product);
    } else {
      if (Array.isArray(product) && product !== null) {
      } else {
        setCartNum(JSON.parse(product).length);
        console.log(JSON.parse(product));
      }
    }
    if (user_session === null) {
    } else {
      setSession(true);
      let fullname = localStorage.getItem("falcon_name");
      setName(fullname);
    }
  };

  useEffect(() => {
    getCart();
  });

  return (
    <Navbar expand="lg" className="bg-body header">
      <Container className="header-container">
        <Navbar.Brand href="/">
          <Image src={logo} fluid className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="bg-light"
          style={{ zIndex: 1050, padding: "10px" }}
        >
          <Nav className="me-auto nav">
            <Nav.Link href="/activities">Activities</Nav.Link>
            <Nav.Link href="/most-selling">Most Selling</Nav.Link>
            <Nav.Link href="/top-offer">Top Offers</Nav.Link>
          </Nav>
          <Nav className="d-flex nav">
            <Nav.Link href="/cart">
              <Badge count={cartNum} showZero={false}>
                <TiShoppingCart size={28} title="Cart" />
              </Badge>
            </Nav.Link>
            {session === true ? (
              <Nav.Link href="/account">
                <RiAccountCircleLine size={28} title="Account" />{" "}
                <span> Welcome, {name}</span>
              </Nav.Link>
            ) : (
              <>
                <Nav.Link href="/login">Login</Nav.Link>
                <Nav.Link href="/signup" className="signup_btn">
                  Signup
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
