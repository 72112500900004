import React, { useState, useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import { falconApiCalling } from "../API/falconApiCalling";
import "./index.css";
import { BrowserView, MobileView } from "react-device-detect";
import { Gallery } from "react-grid-gallery";

export default function Index() {
  const [images, setImages] = useState([]);
  const [productId, setProductId] = useState(
    window.location.pathname.split("/")[2]
  );
  const getProductImages = () => {
    let params = { action: "GET_PRODUCT_IMAGES", pid: productId };
    falconApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setImages(
          res.map((item) => {
            return {
              src: item.original,
              alt: productId,
            };
          })
        );
        console.log("res=====>", res);
      }
    });
  };

  useEffect(() => {
    getProductImages();
  }, []);

  const renderItem = (item, index) => {
    return (
      <>
        <BrowserView>
          <section>
            <Image src={item.original} fluid className="originalImage" />
          </section>
        </BrowserView>
        <MobileView>
          <section>
            <Image src={item.original} fluid className="originalImage_mobile" />
          </section>
        </MobileView>
      </>
    );
  };
  const renderThumbInner = (item, index) => {
    return (
      <section>
        <Image src={item.thumbnail} fluid className="thumbnailClass" />
      </section>
    );
  };

  return (
    <Container fluid>
      <div className={"image_gallery_product"}>
        <BrowserView>
          {images.length > 0 ? (
            // <ImageGallery
            //   items={images}
            //   thumbnailPosition={"left"}
            //   originalWidth={"75%"}
            //   originalHeight={"200px"}
            //   thumbnailClass={"thumbnailClass"}
            //   renderItem={renderItem}
            //   renderThumbInner={renderThumbInner}
            //   useTranslate3D={true}
            // />
            <Gallery images={images} />
          ) : null}
        </BrowserView>
        <MobileView>
          {images.length > 0 ? (
            // <ImageGallery
            //   items={images}
            //   thumbnailPosition={"bottom"}
            //   originalWidth={"100%"}
            //   originalHeight={"200px"}
            //   thumbnailClass={"thumbnailClass"}
            //   renderItem={renderItem}
            //   renderThumbInner={renderThumbInner}
            //   useTranslate3D={true}
            // />
            <Gallery images={images} />
          ) : null}
        </MobileView>
      </div>
    </Container>
  );
}
