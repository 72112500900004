import React from "react";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { Container, Row, Col, Image } from "react-bootstrap";
import error from "../../images/error.gif";
import "./index.css";
export default function Index() {
  return (
    <Container fluid>
      <Header />
      <Container style={{ height: "600px" }}>
        <Row>
          <Col md={4}></Col>
          <Col md={4}>
            <center>
              <Image src={error} style={{ marginTop: "150px" }} />
              <h3 className="failed_text">
                Something went wrong with your Payment Method Please Try Again
                Later
              </h3>
            </center>
          </Col>
          <Col md={4}></Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}
