import React, { useState } from "react";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Tabs } from "antd";
import { CgProfile } from "react-icons/cg";
import { MdOutlineBorderAll } from "react-icons/md";
import { MdFavoriteBorder } from "react-icons/md";
import { IoIosSettings } from "react-icons/io";
import { CiWallet } from "react-icons/ci";
import { CiLogout } from "react-icons/ci";
import Profile from "../../Component/Tabs/Profile";
import Orders from "../../Component/Tabs/Order";
import Wishlist from "../../Component/Tabs/Wishlist";
import Settings from "../../Component/Tabs/Settings";
import Wallet from "../../Component/Tabs/Wallet";
import Logout from "../../Component/Tabs/Logout";
import "./index.css";
export default function Index() {
  const items = [
    {
      key: "1",
      icon: <CgProfile />,
      label: "Profile",
      children: <Profile />,
    },
    {
      key: "2",
      icon: <MdOutlineBorderAll />,
      label: "Bookings",
      children: <Orders />,
    },
    {
      key: "3",
      icon: <MdFavoriteBorder />,
      label: "Wishlist",
      children: <Wishlist />,
    },
    {
      key: "4",
      icon: <IoIosSettings />,
      label: "Settings",
      children: <Settings />,
    },
    {
      key: "5",
      icon: <CiWallet />,
      label: "Wallet",
      children: <Wallet />,
    },
    {
      key: "6",
      icon: <CiLogout />,
      label: "Logout",
      children: <Logout />,
    },
  ];
  const [current, setCurrent] = useState("mail");
  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };
  return (
    <Container fluid>
      <Header />
      <Container className="account_container">
        <Tabs tabPosition={"left"} items={items} />
      </Container>
      <Footer />
    </Container>
  );
}
