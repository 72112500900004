import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import { Button } from "antd";
export default function Index(props) {
  return (
    <Container>
      <Row className="ms-0">
        <Col ms={3}>
          <Button className="counter-btn" onClick={() => props.add()}>
            +
          </Button>
        </Col>
        <Col
          ms={3}
          style={{
            display: "flex",
            height: "50px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="seat">{props.value}</span>
        </Col>
        <Col ms={2}>
          <Button className="counter-btn" onClick={() => props.minus()}>
            -
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
