import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Divider } from "antd";
import "./index.css";
import { falconApiCalling } from "../API/falconApiCalling";
import { Rating } from "react-simple-star-rating";
export default function Index() {
  const [productId, setProductId] = useState(
    window.location.pathname.split("/")[2]
  );
  const [rating, setRating] = useState(5);
  const [product_name, setProduct_name] = useState("");
  const getProductName = () => {
    let params = { action: "GET_PRODUCT_DETAIL", pid: productId };
    falconApiCalling(params).then((res) => {
      if (res.status === "success") {
        setProduct_name(res.product_name);
      }
    });
  };

  useEffect(() => {
    getProductName();
  }, []);

  return (
    <Container className="product_detail_container">
      <Row>
        <Col md={6}>
          <div className="productNameContainer">
            <p className="breadCrumText">
              Destination {">"} Dubai {">"} Dinner Cruise
            </p>
            <h3 className="product-name">{`${product_name}`}</h3>
            <div className="product_rating_container">
              <Rating
                readonly
                initialValue={rating}
                size={15}
                /* Available Props */
              />
              <span className="total_review">6,788 Reviews</span>
            </div>
          </div>
        </Col>
        <Col md={6}></Col>
      </Row>
      <Divider />
    </Container>
  );
}
