import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import "./Slider.css";
import { Slide } from "react-slideshow-image";
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";
import card from "../../images/card.png";
import HomeSearch from "../HomeSearch/index";
import { BrowserView, MobileView } from "react-device-detect";
import { falconApiCalling } from "../API/falconApiCalling";
const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "100% 100%",
  height: "550px",
  borderRadius: "32px",
};

const buttonStyle = {
  width: "30px",
  heighr: "30px",
  border: "0px",
  display: "flex",
};

const properties = {
  prevArrow: (
    <button style={{ ...buttonStyle }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
        <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
      </svg>
    </button>
  ),
  nextArrow: (
    <button style={{ ...buttonStyle }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
        <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
      </svg>
    </button>
  ),
};

export default function Index() {
  const [slider, setSlider] = useState([
    {
      id: 1,
      data: {
        title: "Top Destination Provider In Dubai",
        description: "Asian Continent",
        temp: "42° C",
        temp_type: "Very Hot",
        payment_condition: "We Accept Payment Through All Cards & Banking",
      },
      image:
        "https://da28ojrjakn6f.cloudfront.net/tickets/11109/NEW/img_1606990813_1606992678__atlantisphotostop.jpg?v=1.1.0",
    },
    {
      id: 2,
      data: {
        title: "Top Destination Provider In Dubai",
        description: "Asian Continent",
        temp: "42° C",
        temp_type: "Very Hot",
        payment_condition: "We Accept Payment Through All Cards & Banking",
      },
      image:
        "https://cloudfront-eu-central-1.images.arcpublishing.com/thenational/NCWFU4OF7QYPWQAMSAOYZJVKMY.jpg",
    },
    {
      id: 3,
      data: {
        title: "Top Destination Provider In Dubai",
        description: "Asian Continent",
        temp: "42° C",
        temp_type: "Very Hot",
        payment_condition: "We Accept Payment Through All Cards & Banking",
      },
      image:
        "https://www.timeoutabudhabi.com/cloud/timeoutabudhabi/2021/09/11/aaCUo8qz-free_fall.jpg",
    },
  ]);

  const getBanner = () => {
    let params = { action: "GET_BANNER_LIST" };
    falconApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        let arr = res.map((item) => {
          return {
            id: item.id,
            data: {
              title: item.banner_title,
              description: "Asian Continent",
              temp: item.temprature + "° C",
              temp_type: item.feels,
              payment_condition:
                "We Accept Payment Through All Cards & Banking",
            },
            image: item.web,
            mobile: item.mobile,
          };
        });
        setSlider(arr);
      }
    });
  };

  useEffect(() => {
    getBanner();
  }, []);

  return (
    <>
      <BrowserView>
        <Container>
          <Row>
            <Col md={12} className={"sliderHolder"}>
              <Slide scale={1.4} {...properties}>
                {slider.map((slideImage, index) => (
                  <div key={index}>
                    <div
                      style={{
                        ...divStyle,
                        backgroundImage: `url(${slideImage.image})`,
                      }}
                    >
                      <span className={"inner-container"}>
                        <div className={"title-container"}>
                          <div className={"dot"}></div>
                          <div className={"slider-title"}>
                            <p
                              style={{
                                alignItems: "center",
                                marginTop: "13px",
                              }}
                            >
                              {slideImage.data.title}
                            </p>
                          </div>
                        </div>
                        <div className={"description-container"}>
                          <h2 className="description-container-h2">
                            {slideImage.data.description}
                          </h2>
                        </div>
                        <div className="weather-container">
                          <div className="weather-container-item">
                            <p>{slideImage.data.temp}</p>
                          </div>
                          <div className="weather-container-item">
                            <p>{slideImage.data.temp_type}</p>
                          </div>
                        </div>
                        <div className="slider-btn-container">
                          <button>
                            <BsArrowLeftCircle color={"#FFFFFF"} size={30} />
                          </button>
                          <button>
                            <BsArrowRightCircle color={"#FFFFFF"} size={30} />
                          </button>
                        </div>
                        <div className="bottom-right">
                          <div className="circle">
                            <Image
                              src={card}
                              style={{ width: "45px", height: "45px" }}
                            />
                          </div>
                          <p>{slideImage.data.payment_condition}</p>
                        </div>
                      </span>
                    </div>
                  </div>
                ))}
              </Slide>
            </Col>
          </Row>
          <Container className="main-search-container">
            <Row>
              <Col md={1}></Col>
              <Col md={10} className="search-body">
                <HomeSearch />
              </Col>
              <Col md={1}></Col>
            </Row>
          </Container>
        </Container>
      </BrowserView>
      <MobileView>
        <Container>
          <Row>
            <Col md={12}>
              <Slide scale={1.4} {...properties}>
                {slider.map((slideImage, index) => (
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundSize: "100% 100%",
                        height: "300px",
                        borderRadius: "20px",
                        backgroundImage: `url(${slideImage.mobile})`,
                      }}
                    >
                      <span
                        style={{
                          width: "100%",
                          height: "100%",
                          background: "#0000004b",
                          borderRadius: "20px",
                          position: "relative",
                        }}
                      >
                        <div className={"title-container"}>
                          <div className={"dot"}></div>
                          <div className={"slider-title"}>
                            <p
                              style={{
                                alignItems: "center",
                                marginTop: "13px",
                              }}
                            >
                              {slideImage.data.title}
                            </p>
                          </div>
                        </div>
                        <div className={"description-container"}>
                          <h2
                            className="description-container-h2"
                            style={{ fontSize: "16px" }}
                          >
                            {slideImage.data.description}
                          </h2>
                        </div>
                        <div
                          style={{
                            width: "35%",
                            height: "50px",
                            marginTop: "00px",
                            marginLeft: "10%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="weather-container-item">
                            <p>{slideImage.data.temp}</p>
                          </div>
                          <div className="weather-container-item">
                            <p>{slideImage.data.temp_type}</p>
                          </div>
                        </div>
                        <div className="slider-btn-container">
                          <button>
                            <BsArrowLeftCircle color={"#FFFFFF"} size={30} />
                          </button>
                          <button>
                            <BsArrowRightCircle color={"#FFFFFF"} size={30} />
                          </button>
                        </div>
                        <div className="bottom-right">
                          <div className="circle">
                            <Image
                              src={card}
                              style={{ width: "45px", height: "45px" }}
                            />
                          </div>
                          <p>{slideImage.data.payment_condition}</p>
                        </div>
                      </span>
                    </div>
                  </div>
                ))}
              </Slide>
            </Col>
          </Row>
        </Container>
      </MobileView>
      {/* <Container className={"social-box"}></Container> */}
    </>
  );
}
