import React from "react";
import { Tabs } from "antd";
import { Container } from "react-bootstrap";
import Overview from "./Tabs/Overview";
import Itinerary from "./Tabs/Itinerary";
import Inclusions from "./Tabs/Inclusions";
import Reviews from "./Tabs/Reviews";
import FAQ from "./Tabs/FAQ";
import Essential from "./Tabs/Essential";

const items = [
  {
    key: "1",
    label: "Overview",
    children: <Overview pid={window.location.pathname.split("/")[2]} />,
  },
  {
    key: "2",
    label: "Itinerary",
    children: <Itinerary pid={window.location.pathname.split("/")[2]} />,
  },
  {
    key: "3",
    label: "Inclusions",
    children: <Inclusions pid={window.location.pathname.split("/")[2]} />,
  },
  {
    key: "4",
    label: "Reviews",
    children: <Reviews pid={window.location.pathname.split("/")[2]} />,
  },
  {
    key: "5",
    label: "FAQ",
    children: <FAQ pid={window.location.pathname.split("/")[2]} />,
  },
  {
    key: "6",
    label: "Essential Info",
    children: <Essential pid={window.location.pathname.split("/")[2]} />,
  },
];

export default function Index() {
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <Container>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </Container>
  );
}
