import React, { useEffect, useState } from "react";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { Container } from "react-bootstrap";
import { falconApiCalling } from "../../Component/API/falconApiCalling";
import "./index.css";
export default function Index() {
  const [terms, setTerms] = useState(null);

  const getContent = () => {
    let params = { action: "GET_CONTENT" };
    falconApiCalling(params).then((res) => {
      console.log("res========>", res);
      if (res.status === "success") {
        setTerms(res.data.terms);
      }
    });
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <Container fluid>
      <Header />
      <Container style={{ marginTop: "60px" }}>
        {" "}
        <p
          dangerouslySetInnerHTML={{ __html: terms }}
          className="content_text"
        ></p>
      </Container>
      <Footer />
    </Container>
  );
}
