import React, { useState, useEffect } from "react";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { Container } from "react-bootstrap";
import "./index.css";
import { Button, Flex, Spin, Switch } from "antd";
import { falconApiCalling } from "../../Component/API/falconApiCalling";
export default function Index() {
  const [isLoading, setIsLoading] = useState(true);
  const [invoice, setInvoice] = useState("#");
  const [queryParams, setQueryParams] = useState({});
  const [firstname, setFirstname] = useState(
    window.location.pathname.split("/")[2]
  );
  const [lastname, setLastname] = useState(
    window.location.pathname.split("/")[3]
  );
  const [email, setEmail] = useState(window.location.pathname.split("/")[4]);
  const [amount, setAmount] = useState(window.location.pathname.split("/")[5]);
  const [country, setCountry] = useState(
    window.location.pathname.split("/")[6]
  );
  const [address, setAddress] = useState(
    window.location.pathname.split("/")[7]
  );
  const [town, setTown] = useState(window.location.pathname.split("/")[8]);
  const [phone, setPhone] = useState(window.location.pathname.split("/")[9]);
  const [pickup, setPickup] = useState(window.location.pathname.split("/")[10]);

  const confirmBooking = (
    chargeUID,
    createdAt,
    paymentLinkId,
    status,
    transactionId,
    firstname,
    lastname,
    email,
    amount,
    country,
    address,
    town,
    phone,
    pickup
  ) => {
    let falcon_cart = localStorage.getItem("falcon_cart");

    console.log("cart", JSON.parse(falcon_cart));

    let params = {
      action: "CONFIRM_BOOKING",
      chargeUID: chargeUID,
      createdAt: createdAt,
      paymentLinkId: paymentLinkId,
      status: status,
      transactionId: transactionId,
      firstname: firstname,
      lastname: lastname,
      email: email,
      amount: amount,
      country: country,
      address: address,
      town: town,
      phone: phone,
      pickup: pickup,
      item: falcon_cart,
    };
    falconApiCalling(params).then((res) => {
      if (res.status === "success") {
        setIsLoading(false);
        setInvoice(res.invoice);
        localStorage.removeItem("falcon_cart");
      }
    });
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const chargeUID = searchParams.get("chargeUID");
    const createdAt = searchParams.get("createdAt");
    const paymentLinkId = searchParams.get("paymentLinkId");
    const status = searchParams.get("status");
    const transactionId = searchParams.get("transactionId");
    confirmBooking(
      chargeUID,
      createdAt,
      paymentLinkId,
      status,
      transactionId,
      firstname,
      lastname,
      email,
      amount,
      country,
      address,
      town,
      phone,
      pickup
    );
  }, [queryParams]);

  return (
    <Container fluid>
      <Header />
      {isLoading === true ? (
        <Container className="success_container">
          <div className="processing_message">
            <h3 className="process_message text-center">
              Please wait while we are confirming your booking...
            </h3>
          </div>
          <Spin size="large" className="text-center spin" />
        </Container>
      ) : (
        <Container className="success_container">
          <div className="processing_message">
            <h3 className="process_message text-center">
              Thank you for Booking with us...
            </h3>
          </div>
          <br></br>
          <a
            href={"https://itechanalogy.com/FALCON/API/invoice/" + invoice}
            className="download_btn"
            target={"_blank"}
          >
            Download Invoice
          </a>
        </Container>
      )}
      <Footer />
    </Container>
  );
}
