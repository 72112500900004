import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { falconApiCalling } from "../API/falconApiCalling";
import wishlist from "../../images/wishlist.png";
import star from "../../images/star.png";
import clock from "../../images/clock.png";
import checkmark from "../../images/checkmark.png";
import "./index.css";
export default function Index(props) {
  const [product, setProduct] = useState([]);

  const getCategoryProduct = () => {
    let params = { action: "GET_CATEGORY_PRODUCT", cid: props.cid };
    falconApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setProduct(res);
      } else {
        setProduct([]);
      }
    });
  };

  useEffect(() => {
    getCategoryProduct();
  }, []);

  return (
    <Container>
      <div style={{ marginTop: "20px" }}>
        <Row>
          {product.map((item) => {
            return (
              <Col
                md={4}
                style={{
                  padding: "10px",
                  paddingBottom: "10px",
                }}
              >
                <div className="item-container-product_category">
                  <a
                    href={"/product/" + item.id}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="item-image-container">
                      <Image src={item.image} className={"item-image"} />
                    </div>
                    <Row style={{ marginTop: "8px" }}>
                      <Col md={10}>
                        <h4 className="item-product-name">{item.name}</h4>
                        <span className="item-product-subtitle">
                          {item.sub_title}
                        </span>
                      </Col>
                      <Col md={1}>
                        <Image src={wishlist} className={"wishlist-icon"} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "8px" }}>
                      <Col md={8}>
                        <span className="item-product-subtitle">from</span>
                        <h4 className="item-product-name">
                          AED {item.charges}.00/-
                        </h4>
                        <span
                          className="item-product-subtitle"
                          style={{ marginTop: "-20px" }}
                        >
                          *Price varies
                        </span>
                      </Col>
                      <Col md={4}>
                        <Image src={star} className={"star-icon"} />
                        <span className="item-product-subtitle">
                          {item.rating}
                        </span>
                      </Col>
                    </Row>
                    <div>
                      <span>
                        <Image
                          src={clock}
                          style={{ width: "15px", height: "15px" }}
                        />
                        {"  "}
                        <span className="item-product-subtitle">
                          {" "}
                          {item.time}
                        </span>
                      </span>
                      <div style={{ flexDirection: "row" }}>
                        {item.terms[0].map((item) => {
                          return (
                            <span style={{ marginLeft: "0px" }}>
                              <Image
                                src={checkmark}
                                style={{ width: "12px", height: "8px" }}
                              />
                              {"  "}
                              <span className="item-product-subtitle">
                                {" "}
                                {item.title}
                              </span>
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </a>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </Container>
  );
}
