import React, { useState } from "react";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { Container, Row, Col, Image } from "react-bootstrap";
import location from "../../images/location.png";
import phone from "../../images/phone-ringing.png";
import whatsapp from "../../images/whatsapp.png";
import email from "../../images/mail.png";
import { Alert, Form, Input, Typography, Button } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "./index.css";
import { falconApiCalling } from "../../Component/API/falconApiCalling";
import { BrowserView, MobileView } from "react-device-detect";

export default function Index() {
  const [form] = Form.useForm();
  const [list, setlist] = useState([
    {
      id: 1,
      title: "Headquarter",
      description:
        "Meena Bazaar, Sharafi Building Bur Dubai - Al Fahidi - Dubai",
      icon: location,
      link: "https://www.google.com/maps/dir//Aladdin%20Adventure%20Travel%20&%20Tours%20LLC",
    },
    {
      id: 2,
      title: "Phone Number",
      description: "+971502646557",
      icon: phone,
      link: "tel:+971502646557",
    },
    {
      id: 3,
      title: "WhatsApp Number",
      description: "+971502646557",
      icon: whatsapp,
      link: "https://api.whatsapp.com/send?phone=971502646557&text=Hello",
    },
    {
      id: 4,
      title: "Email us",
      description: "help@aladdinadventuretours.com",
      icon: email,
      link: "mailto:help@aladdinadventuretours.com",
    },
  ]);

  const onFinish = (values) => {
    let params = {
      action: "SUBMIT_CONTACT",
      name: values.name,
      email: values.email,
      phone: values.phone,
      message: values.message,
    };
    falconApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <Container fluid className="contact_main_container">
      <Header />
      <div style={{ height: "1500px" }}>
        <BrowserView>
          <Container fluid className="map_container">
            <iframe
              width="100%"
              height="400"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              id="gmap_canvas"
              src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Meena%20Bazaar,%20Sharafi%20Building%20-%20Shop%20No%20-%2013%2025C%20St%20-%20Bur%20Dubai%20-%20Al%20Fahidi%20-%20Dubai%20+(Aladdin%20Adventure%20Travel%20&amp;%20Tours%20LLC)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>{" "}
          </Container>
          <br></br>
          <Container className="contact_box">
            <Container>
              <div
                className="gent_in_touch_container text-center"
                style={{ marginTop: "60px" }}
              >
                <h3 className="contact_title_main">Get In Touch</h3>
              </div>
              <div className="gent_in_touch_container text-center">
                <p className="gent_in_touch_description">
                  You will get an immediate reply, wait a minute.
                </p>
              </div>
            </Container>
            <Container style={{ marginTop: "80px" }}>
              <Row>
                <br></br>
                <br></br>
                <Col md={1}></Col>
                <Col md={4} className="contact_section_left">
                  <Container style={{ marginTop: "80px" }}>
                    {list.map((item) => {
                      return (
                        <Container key={item.id} className="list_container">
                          <Row>
                            <Col md={3}>
                              <a href={item.link}>
                                <div className="contact_icon_holder">
                                  <Image
                                    src={item.icon}
                                    className="contact_icon"
                                  />
                                </div>
                              </a>
                            </Col>
                            <Col md={8}>
                              <span className="contact_title">
                                {item.title}
                              </span>
                              <br></br>
                              <span className="contact_description">
                                {item.description}
                              </span>
                            </Col>
                          </Row>
                        </Container>
                      );
                    })}
                  </Container>
                </Col>
                <Col md={7} className="contact_section_right">
                  <Container>
                    <div style={{ marginBottom: "50px" }}>
                      <h3 className="contact_form_heading">Leave A Message</h3>
                      <p className="contact_form_description">
                        Your email address will not be published. Required
                        fields are marked *
                      </p>
                    </div>
                    <Form
                      form={form}
                      name="dependencies"
                      autoComplete="off"
                      labelCol={{
                        span: 8,
                      }}
                      wrapperCol={{
                        span: 16,
                      }}
                      layout="vertical"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        <Form.Item
                          label="Name"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Name is required",
                            },
                          ]}
                          style={{
                            display: "inline-block",
                            width: "calc(50% - 8px)",
                          }}
                        >
                          <Input
                            placeholder="Full Name"
                            className="contact_input"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Email address"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Email address is required",
                            },
                          ]}
                          style={{
                            display: "inline-block",
                            width: "calc(50% - 8px)",
                            margin: "0 8px",
                          }}
                        >
                          <Input
                            placeholder="Email Address"
                            className="contact_input"
                          />
                        </Form.Item>
                      </Form.Item>
                      <Form.Item
                        label="Phone Number"
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Phone Number is required",
                          },
                        ]}
                        style={{
                          width: "calc(100% - 8px)",
                        }}
                      >
                        <Input
                          placeholder="Phone Number"
                          className="contact_input"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Message"
                        name="message"
                        rules={[
                          {
                            required: true,
                            message: "Phone Number is required",
                          },
                        ]}
                        style={{
                          width: "calc(100% - 8px)",
                        }}
                      >
                        <Input.TextArea placeholder="Write your message here..." />
                      </Form.Item>
                      <Form.Item label=" " colon={false}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="contact_submit"
                        >
                          Send Message
                        </Button>
                      </Form.Item>
                    </Form>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Container>
        </BrowserView>
        <MobileView>
          <Container fluid className="map_container">
            <iframe
              width="100%"
              height="400"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              id="gmap_canvas"
              src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Meena%20Bazaar,%20Sharafi%20Building%20-%20Shop%20No%20-%2013%2025C%20St%20-%20Bur%20Dubai%20-%20Al%20Fahidi%20-%20Dubai%20+(Aladdin%20Adventure%20Travel%20&amp;%20Tours%20LLC)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>{" "}
          </Container>
          <br></br>
          <Container>
            <div style={{ marginBottom: "50px" }}>
              <h3 className="contact_form_heading">Leave A Message</h3>
              <p className="contact_form_description">
                Your email address will not be published. Required fields are
                marked *
              </p>
            </div>
            <Form
              form={form}
              name="dependencies"
              autoComplete="off"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item
                style={{
                  marginBottom: 0,
                }}
              >
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Name is required",
                    },
                  ]}
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                  }}
                >
                  <Input placeholder="Full Name" className="contact_input" />
                </Form.Item>
                <Form.Item
                  label="Email address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email address is required",
                    },
                  ]}
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                    margin: "0 8px",
                  }}
                >
                  <Input
                    placeholder="Email Address"
                    className="contact_input"
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Phone Number is required",
                  },
                ]}
                style={{
                  width: "calc(100% - 8px)",
                }}
              >
                <Input placeholder="Phone Number" className="contact_input" />
              </Form.Item>
              <Form.Item
                label="Message"
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Phone Number is required",
                  },
                ]}
                style={{
                  width: "calc(100% - 8px)",
                }}
              >
                <Input.TextArea placeholder="Write your message here..." />
              </Form.Item>
              <Form.Item label=" " colon={false}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="contact_submit"
                >
                  Send Message
                </Button>
              </Form.Item>
            </Form>
          </Container>
          <br></br>
          <Container style={{ marginTop: "10px" }}>
            {list.map((item) => {
              return (
                <Container key={item.id} className="list_container">
                  <Row>
                    <Col xs={3} md={3}>
                      <a href={item.link}>
                        <div className="contact_icon_holder">
                          <Image src={item.icon} className="contact_icon" />
                        </div>
                      </a>
                    </Col>
                    <Col xs={9} md={9}>
                      <span className="contact_title">{item.title}</span>
                      <br></br>
                      <span className="contact_description">
                        {item.description}
                      </span>
                    </Col>
                  </Row>
                </Container>
              );
            })}
          </Container>
        </MobileView>
      </div>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Container>
  );
}
