import React, { useState } from "react";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { Container, Row, Col, Image } from "react-bootstrap";
import desert_safari from "../../images/desert_safari.webp";
import { Rating } from "react-simple-star-rating";
import checkmark from "../../images/checkmark.png";
import { Button } from "antd";
import { WhatsAppOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import "./index.css";
export default function Index() {
  const [desert_safari, setDesert_safari] = useState([
    {
      pid: 6,
      type: "ECONOMY",
      name: "Desert Safari By 4X4 With Dune Bashing",
      image: "https://itechanalogy.com/FALCON/API/uploads/193029.jpg",
      price: "AED 89",
      off: "10%",
      orignal: "AED 99",
      rating: 4.5,
      review: "134+ review",
      itineary: [
        {
          id: 1,
          title: "Pickup and Drop off",
        },
        {
          id: 2,
          title: "Centralized Pickup Location",
        },
        {
          id: 3,
          title: "Dune Bashing by 4X4",
        },
        {
          id: 4,
          title: "Sand Boarding",
        },
        {
          id: 5,
          title: "Sunset Picture Point",
        },
        {
          id: 6,
          title: "Camel Ride",
        },
        {
          id: 7,
          title: "Arabic Coffee & Fresh Dates",
        },
        {
          id: 8,
          title: "Hubbly Bubble",
        },
        {
          id: 9,
          title: "Traditional Costumes",
        },
        {
          id: 10,
          title: "Unlimited Soft Drinks, Tea, Coffee, Water",
        },
        {
          id: 11,
          title: "Heena Paintings",
        },
        {
          id: 12,
          title: "2 Belly Dance Show",
        },
        {
          id: 13,
          title: "2 Fire Dance Show",
        },
        {
          id: 14,
          title: "2 Tanoura Dance Show",
        },
        {
          id: 15,
          title: "BBQ & Buffet Dinner (Veg & Non-Veg Both Available)",
        },
      ],
    },
    {
      pid: 7,
      name: "Desert Safari VIP Package",
      type: "TRENDING",
      image: "https://itechanalogy.com/FALCON/API/uploads/237322.avif",
      price: "AED 139",
      off: "7%",
      orignal: "AED 149",
      rating: 4.3,
      review: "99+ review",
      itineary: [
        {
          id: 1,
          title: "Pickup and Drop off",
        },
        {
          id: 2,
          title: "Centralized Pickup Location",
        },
        {
          id: 3,
          title: "Dune Bashing by 4X4",
        },
        {
          id: 4,
          title: "Sand Boarding",
        },
        {
          id: 5,
          title: "Sunset Picture Point",
        },
        {
          id: 6,
          title: "Camel Ride",
        },
        {
          id: 7,
          title: "Arabic Coffee & Fresh Dates",
        },
        {
          id: 8,
          title: "Hubbly Bubble",
        },
        {
          id: 9,
          title: "Traditional Costumes",
        },
        {
          id: 10,
          title: "Unlimited Soft Drinks, Tea, Coffee, Water",
        },
        {
          id: 11,
          title: "Heena Paintings",
        },
        {
          id: 12,
          title: "2 Belly Dance Show",
        },
        {
          id: 13,
          title: "2 Fire Dance Show",
        },
        {
          id: 14,
          title: "2 Tanoura Dance Show",
        },
        {
          id: 15,
          title: "BBQ & Buffet Dinner (Veg & Non-Veg Both Available)",
        },
      ],
    },
    {
      pid: 8,
      name: "Desert Safari With Dune Bashing & Quad Bike",
      type: "BEST SELLER",
      rating: 4.8,
      review: "124+ review",
      image: "https://itechanalogy.com/FALCON/API/uploads/856916.webp",
      price: "AED 235",
      off: "4%",
      orignal: "AED 245",
      itineary: [
        {
          id: 1,
          title: "Pickup and Drop off",
        },
        {
          id: 2,
          title: "Centralized Pickup Location",
        },
        {
          id: 3,
          title: "Dune Bashing by 4X4",
        },
        {
          id: 4,
          title: "Sand Boarding",
        },
        {
          id: 5,
          title: "Sunset Picture Point",
        },
        {
          id: 6,
          title: "Camel Ride",
        },
        {
          id: 7,
          title: "Arabic Coffee & Fresh Dates",
        },
        {
          id: 8,
          title: "Hubbly Bubble",
        },
        {
          id: 9,
          title: "Traditional Costumes",
        },
        {
          id: 10,
          title: "Unlimited Soft Drinks, Tea, Coffee, Water",
        },
        {
          id: 11,
          title: "Heena Paintings",
        },
        {
          id: 12,
          title: "2 Belly Dance Show",
        },
        {
          id: 13,
          title: "2 Fire Dance Show",
        },
        {
          id: 14,
          title: "2 Tanoura Dance Show",
        },
        {
          id: 15,
          title: "BBQ & Buffet Dinner (Veg & Non-Veg Both Available)",
        },
      ],
    },
  ]);

  const redirect = (id) => {
    window.location.href = "https://aladdinadventuretours.com/product/" + id;
  };
  const whtasapp = (id, name) => {
    let message =
      "Hello Aladdin Tours, I want to book " +
      name +
      " https://aladdinadventuretours.com/product/" +
      id;
    window.location.href =
      "https://api.whatsapp.com/send?phone=971502646557&text=" + message;
  };

  return (
    <Container fluid style={{ padding: 0, margin: 0, position: "relative" }}>
      <Header />
      <Container fluid className="banner_container"></Container>
      <Container className="banner_overlay" fluid>
        <h3 className="overlay_title">Desert Safari</h3>
      </Container>
      <Container className="desert_safari_main_container">
        <Row>
          {desert_safari.map((item) => {
            return (
              <Col
                md={4}
                className={
                  item.pid === 6 || item.pid === 8
                    ? "desert_safari_main_col_top_10"
                    : "desert_safari_main_col"
                }
              >
                <div className="desert_safari_inner_item">
                  <div
                    className="item_image_container"
                    style={{ position: "relative" }}
                  >
                    <Image src={item.image} fluid className="item_image" />
                    <div
                      className="off_percentage"
                      style={{
                        position: "absolute",
                        top: "20px",
                        left: "20px",
                        background:
                          "linear-gradient(90deg, #FF9D4B 9.15%, #FA126C 101.52%)",
                        borderRadius: "10px",
                        height: "25px",
                      }}
                    >
                      <span
                        className="off_text"
                        style={{ color: "#FFF", fontSize: "12px" }}
                      >
                        {item.type}
                      </span>
                    </div>
                    <div
                      className="off_percentage"
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        background: "#28B0A6",
                      }}
                    >
                      <span className="off_text" style={{ color: "#FFF" }}>
                        {item.off} Off
                      </span>
                    </div>
                  </div>
                  <div className="item_package_name">
                    <h3 className="item_package_title text-left">
                      {item.name}
                    </h3>
                  </div>
                  <div className="item_price_container">
                    <span className="offer_price">{item.price}/-</span>
                    <span className="orignal_price">{item.orignal}/-</span>
                  </div>
                  <div className="item_review_container">
                    <Rating
                      initialValue={item.rating}
                      allowFraction={true}
                      size={15}
                    />
                    <span className="review_text">{item.review}</span>
                  </div>
                  <div className="item_itineary_container">
                    {item.itineary.map((item) => {
                      return (
                        <div className="inner_itineary">
                          <Image src={checkmark} />
                          <span className="review_text">{item.title}/-</span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="item_btn_container">
                    <Button
                      onClick={() => redirect(item.pid)}
                      className="item_btn"
                      icon={<ShoppingCartOutlined />}
                    >
                      Book Now
                    </Button>
                    <Button
                      onClick={() => whtasapp(item.pid, item.name)}
                      className="item_btn"
                      style={{ background: "#25d366" }}
                      icon={<WhatsAppOutlined />}
                    >
                      Whatsapp
                    </Button>
                  </div>
                </div>

                <br></br>
                <br></br>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}
