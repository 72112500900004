import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import inclusion_icon from "../../../images/inclusion_icon.png";
import { falconApiCalling } from "../../API/falconApiCalling";
export default function Inclusions(props) {
  const { pid } = props;
  const [inclusion, setInclusion] = useState("");
  const getInclusion = () => {
    let params = { action: "GET_PRODUCT_INCLUSION", pid: pid };
    falconApiCalling(params).then((res) => {
      if (res.status === "success") {
        setInclusion(res.inclusion);
      }
    });
  };

  useEffect(() => {
    getInclusion();
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col md={1}>
          <Image src={inclusion_icon} />
        </Col>
        <Col md={11}>
          <p className="inclusion_header">{"Inclusion"}</p>
          <div>
            <div dangerouslySetInnerHTML={{ __html: inclusion }}></div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
