import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Product from "../Pages/Product";
import Category from "../Pages/Category";
import Activities from "../Pages/Activities";
import MostSelling from "../Pages/MostSelling";
import TopOffer from "../Pages/TopOffer";
import Login from "../Pages/Auth/Login/index";
import Signup from "../Pages/Auth/Signup/index";
import Cart from "../Pages/Cart/index";
import Checkout from "../Pages/Checkout/index";
import Success from "../Pages/Success";
import Terms from "../Pages/Terms";
import Privacy from "../Pages/Privacy";
import About from "../Pages/About";
import Contact from "../Pages/Contact";
import Account from "../Pages/Account";
import Failed from "../Pages/Failed";
import DesertSafari from "../Pages/DesertSafari";
export default function Index() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/product/:id" element={<Product />}></Route>
        <Route path="/category/:id/:name" element={<Category />}></Route>
        <Route path="/activities" element={<Activities />}></Route>
        <Route path="/most-selling" element={<MostSelling />}></Route>
        <Route path="/top-offer" element={<TopOffer />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/checkout" element={<Checkout />}></Route>
        <Route
          path="/success/:firtname/:lastname/:email/:amount/:country/:address/:town/:phone/:pickup"
          element={<Success />}
        ></Route>
        <Route path="/terms" element={<Terms />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/account" element={<Account />}></Route>
        <Route path="/failed" element={<Failed />}></Route>
        <Route path="/desert-safari" element={<DesertSafari />}></Route>
      </Routes>
    </Router>
  );
}
