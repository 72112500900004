import React, { useState, useEffect } from "react";
import "./index.css";
import { Container, Row, Col, Image, Collapse } from "react-bootstrap";
import map from "../../../images/map.png";
import arrow_down from "../../../images/arrow_down.png";
import marker from "../../../images/marker.png";
import { falconApiCalling } from "../../API/falconApiCalling";
import { Divider } from "antd";
export default function Itinerary(props) {
  const { pid } = props;
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(1);
  const getProductItineary = () => {
    let params = { action: "GET_ITINERY_FRONTEND", pid: pid };
    falconApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setData(res);
      }
    });
  };

  useEffect(() => {
    getProductItineary();
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col md={1}>
          <Image src={map} />
        </Col>
        <Col md={11} className="itineary-header">
          <h2 className="itineary-header-text">Tour Plan</h2>
        </Col>
        <Row>
          {data.map((item, index) => {
            return (
              <Col md={12} key={index}>
                <Container
                  className="itineary-top-header"
                  onClick={() => setSelected(index)}
                >
                  <Row>
                    <Col md={1} className="bubble_holder">
                      <div className="bubble">
                        <span>{index + 1}</span>
                      </div>
                    </Col>
                    <Col md={10} className="itineary_title_holder">
                      <span className="itineary_main_title">{item.title}</span>
                    </Col>
                    <Col md={1} className="arrow_holder">
                      <Image src={arrow_down} className="arrow_icon" />
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <Collapse in={index === selected ? true : false}>
                    <div className="inner-collapse">
                      <div style={{ height: "10px", marginTop: "20px" }}>
                        <h3 className="itineary_sub_title">{item.sub_title}</h3>
                      </div>
                      <Divider />
                      <div>
                        <p>{item.description}</p>
                      </div>
                      <Container className="initneray_container_option">
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "130px",
                          }}
                        >
                          <Col md={3} className="itineary_option">
                            <div className="map_box"></div>
                          </Col>
                          <Col md={3} className="itineary_option">
                            <div className="map_box">
                              <a
                                href={
                                  "https://maps.google.com/?q=" +
                                  item.latitude +
                                  "," +
                                  item.longitude +
                                  ""
                                }
                                rel={"noreferer"}
                                target={"_blank"}
                                className="map_box_anchor"
                              >
                                <div
                                  style={{
                                    justifyContent: "center",
                                  }}
                                >
                                  <center>
                                    <Image src={marker} />
                                  </center>
                                  <p className="view_on_map">View on Map</p>
                                </div>
                              </a>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Collapse>
                </Container>
              </Col>
            );
          })}
        </Row>
      </Row>
    </Container>
  );
}
