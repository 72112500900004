import React from "react";
import Header from "../../../Component/Header";
import Footer from "../../../Component/Footer";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Form, Input, message, Flex, Checkbox } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { falconApiCalling } from "../../../Component/API/falconApiCalling";
import "./index.css";

export default function Index() {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    if (values.password === values.cnf_password) {
      let params = {
        action: "CREATE_ACCOUNT",
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        password: values.password,
      };
      falconApiCalling(params).then((res) => {
        if (res.status === "success") {
          message.success(res.message);
          localStorage.setItem("falcon_user", res.user);
          localStorage.setItem(
            "falcon_name",
            values.firstname + " " + values.lastname
          );
          window.location.href = "/";
        } else {
          message.error(res.message);
        }
      });
    } else {
      message.error("Both Password Should Be Same");
    }
  };
  const onFinishFailed = () => {
    message.error("Please check the required field.");
  };

  return (
    <Container fluid>
      <Header />
      <Container className="login-container">
        <Row>
          <Col md={4}></Col>
          <Col md={4}>
            <Form
              form={form}
              variant="filled"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item>
                <Form.Item
                  name="firstname"
                  label="Firstname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your firstname",
                    },
                  ]}
                  style={{ display: "inline-block", width: "calc(50% - 8px)" }}
                >
                  <Input placeholder="Firstname" />
                </Form.Item>
                <Form.Item
                  name="lastname"
                  label="Lastname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your lastname",
                    },
                  ]}
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                    margin: "0 8px",
                  }}
                >
                  <Input placeholder="Lastname" />
                </Form.Item>
              </Form.Item>
              <Form.Item
                name="email"
                label="Username or email address *"
                rules={[
                  {
                    required: true,
                    message: "Please input your Username or Email",
                  },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password *"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item
                name="cnf_password"
                label="Confirm Password *"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Confirm Password"
                />
              </Form.Item>
              <Form.Item>
                <p style={{ textAlign: "justify" }}>
                  Your personal data will be used to support your experience
                  throughout this website, to manage access to your account, and
                  for other purposes described in our privacy policy.
                </p>
                <Flex justify="space-between" align="center">
                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Please accept Terms & Condition",
                      },
                    ]}
                  >
                    <Checkbox className="forgot_password">
                      By Clicking Signup you will be agree to{" "}
                      <a
                        href="/terms"
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        Terms & Condition
                      </a>{" "}
                      &{" "}
                      <a
                        href="/privacy"
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        Privacy Policy
                      </a>
                      . & I want to receive updates about products and
                      promotions.
                    </Checkbox>
                  </Form.Item>
                </Flex>
              </Form.Item>

              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="btn-login"
                >
                  Signup
                </Button>
                <br></br>
                <br></br>
                <a href="/login" className="register-now">
                  Already have an account? Login!
                </a>
              </Form.Item>
            </Form>
          </Col>
          <Col md={4}></Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}
