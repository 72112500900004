import React, { useEffect, useState } from "react";
import "./index.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import Header from "../../Component/Header/index";
import Footer from "../../Component/Footer/index";
import { falconApiCalling } from "../../Component/API/falconApiCalling";
import Imgix from "react-imgix";
import { Button } from "antd";
import { BrowserView, MobileView } from "react-device-detect";
import { ToastContainer, toast } from "react-toastify";

export default function Index() {
  const [data, setData] = useState([]);
  const [totalCart, setTotalCart] = useState(0);
  const [coupon, setCoupon] = useState(null);
  const [payurl, setUrl] = useState("https://");
  const getLocalCart = () => {
    let localData = localStorage.getItem("falcon_cart");
    if (localData === null) {
      console.log("cart yes");
    } else {
      let d = JSON.parse(localData);
      setData(d);
    }
  };

  const getPaymentURl = () => {
    // const options = {
    //   method: "POST",

    //   headers: {
    //     accept: "application/json",
    //     Authorization: "Bearer sk-29f0f420-5c5b-445b-8fc4-67cfdea422c5",
    //     "content-type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     title: "Chocolate Box - Small",
    //     description: "12pcs Chocolate Box",
    //     active: true,
    //     return_url: "https://myawesomewebsite.com/paymentSuccess",
    //     failure_return_url: "https://failureurl.com/paymentFailure",
    //     processing_fee_percentage: 3,
    //     amount: 119.99,
    //     amount_currency: "AED",
    //     link_type: "standalone",
    //     enable_tabby: false,
    //     enable_message: false,
    //     enable_tips: false,
    //     save_card: "off",
    //     enable_customer_details: false,
    //     enable_quantity: false,
    //     enable_qr_code: false,
    //     send_customer_receipt: false,
    //     hold_and_charge_later: false,
    //   }),
    // };

    // fetch(
    //   "https://sandbox.dev.business.mamopay.com/manage_api/v1/links",
    //   options
    // )
    //   .then((response) => response.json())
    //   .then((response) => console.log("res======>", response))
    //   .catch((err) => console.error("error======>", err));
    let params = { action: "GET_PAYMENT_URL" };
    falconApiCalling(params).then((res) => {
      console.log(res);
      setUrl(res.payment_url);

      class MamoPay {
        constructor() {
          this.consumerUrl = encodeURIComponent(window.location.href);
        }
        getDomain(url) {
          const parts = url.split("/");
          if (url.startsWith("http://")) {
            return parts[0] + "//" + parts[2];
          } else if (url.startsWith("https://")) {
            return parts[0] + "//" + parts[2];
          } else {
            return null;
          }
        }
        addIframeToWebsite(elementID, paymentLinkUrl) {
          const element = document.getElementById(elementID);
          if (element === null) {
            console.error(
              `Failed to locate element with ID ${elementID} from the DOM`
            );
            return;
          }
          const iframe = document.createElement("iframe");
          const domainName = this.getDomain(paymentLinkUrl);
          iframe.src = `${paymentLinkUrl}?consumer=${this.consumerUrl}`;
          iframe.setAttribute("crossorigin", "anonymous");
          iframe.setAttribute("id", "iframe-mamo-checkout");
          iframe.setAttribute("allowTransparency", "true");
          iframe.setAttribute("allow", "payment");
          iframe.allowTransparency = "true";
          iframe.style.backgroundColor = "transparent";
          iframe.style.width = "100%";
          iframe.style.height = "100%";
          iframe.style.border = "0";
          iframe.style.display = "block";
          iframe.style.zIndex = 99999;
          element.appendChild(iframe);
          iframe.onload = function () {
            window.addEventListener("message", (event) => {
              const iframe = document.getElementById("iframe-mamo-checkout");
              if (event.origin !== domainName) {
                return;
              }
              if (
                event.data === "closeIframe" ||
                event.message === "closeIframe"
              ) {
                iframe.style.display = "none";
              }
              if (
                event.data === "checkout-complete" ||
                event.message === "checkout-complete"
              ) {
                iframe.style.display = "none";
              }
              if (
                event.data === "connectionEstablished" ||
                event.message === "connectionEstablished"
              ) {
                console.log("connection establised");
              }
              if (
                event.data.type &&
                event.data.type === "confirmation_required"
              ) {
                iframe.style.display = "none";
                window.location.replace(event.data.payload);
              }
            });
          };
        }
      }
      window.onload = () => {
        const checkoutDiv = document.getElementById("mamo-checkout");
        if (checkoutDiv !== null) {
          const mamoPay = new MamoPay();
          mamoPay.addIframeToWebsite(
            "mamo-checkout",
            checkoutDiv.getAttribute("data-src")
          );
        }
      };
      const mamoPay = new MamoPay();
      mamoPay.addIframeToWebsite("mamo-checkout-element", res.payment_url);
    });
  };
  const calculateAmount = (
    numAdult,
    numChild,
    adultPrice,
    childPrice,
    transport,
    addOn
  ) => {
    let addOnTotal = 0;
    if (Array.isArray(addOn)) {
      addOnTotal = calculateTotalAddOn(addOn, numAdult, numChild);
    } else {
      addOnTotal = 0;
    }
    let adultTotal = parseInt(numAdult) * parseInt(adultPrice);
    let childTotal = parseInt(numChild) * parseInt(childPrice);
    let total = adultTotal + childTotal + transport + parseInt(addOnTotal);

    return total;
  };

  const calculateTotalAddOn = (addOn, adult, child) => {
    if (Array.isArray(addOn)) {
      const totalPeople = adult + child;
      return addOn.reduce((total, ticket) => {
        if (ticket.type === "Per Pax") {
          return total + totalPeople * ticket.price;
        } else {
          return total + ticket.price;
        }
      }, 0);
    } else {
      return 0;
    }
  };

  const updatePay = () => {
    console.log(payurl.length);
    if (payurl.length > 10) {
    }
  };

  const doCheckout = () => {
    window.location.href = "/checkout";
  };

  const checkCoupon = () => {
    if (coupon === null) {
      toast.error("Please enter coupon");
    } else {
      let params = { action: "VALIDATE_COUPON", coupon: coupon };
      falconApiCalling(params).then((res) => {
        console.log(res);
      });
    }
  };

  useEffect(() => {
    getLocalCart();
  }, []);
  useEffect(() => {
    getPaymentURl();
  }, []);

  useEffect(() => {
    updatePay();
  }, []);

  const getTotal = () => {
    let total = 0;
    let addOn = 0;
    const adultSubtotal = data.reduce((acc, booking) => {
      return acc + booking.adult * booking.type.adult;
    }, 0);

    const childSubtotal = data.reduce((acc, booking) => {
      return acc + booking.child * booking.type.child;
    }, 0);

    const transportCharges = data.reduce((acc, booking) => {
      return acc + booking.transport;
    }, 0);

    const addOnCharges = data.reduce((totalAddOnCost, item) => {
      if (Array.isArray(item.addOn) && item.addOn.length > 0) {
        const totalPeople = item.adult + item.child;
        const addOnCost = item.addOn.reduce((total, addOnItem) => {
          if (addOnItem.type === "Per Pax") {
            return total + totalPeople * parseFloat(addOnItem.price);
          } else {
            return total + parseFloat(addOnItem.price);
          }
        }, 0);
        return totalAddOnCost + addOnCost;
      }
      return totalAddOnCost;
    }, 0);

    const totalCost =
      adultSubtotal + childSubtotal + transportCharges + addOnCharges;
    return totalCost;
  };
  const removeProduct = (id) => {
    let localData = localStorage.getItem("falcon_cart");
    let d = JSON.parse(localData);
    let newArr = d.filter((item) => item.pid !== id);
    localStorage.setItem("falcon_cart", JSON.stringify(newArr));
    setData(newArr);
  };

  const getAddOn = (item) => {
    if (Array.isArray(item)) {
      item.map((data) => {
        console.log(data.label);
        return data.label;
      });
    } else {
      return "N/A";
    }
  };
  return (
    <Container fluid>
      <Header />

      <Container className="cart-container">
        <Row>
          <Col md={8} style={{ minHeight: "800px" }}>
            {data.map((item) => {
              return (
                <Container className="cart_mainContainer">
                  <Row>
                    <Col md={2} className="cart-product-img-holder">
                      <BrowserView>
                        <Image
                          src={item.image}
                          className="cart-product-image"
                        />
                      </BrowserView>
                      <MobileView>
                        <Image
                          src={item.image}
                          className="cart-product-image_mobile"
                        />
                      </MobileView>
                    </Col>
                    <Col md={6}>
                      <br></br>

                      <span className="cart-product-title">{item.pname}</span>
                      <br></br>
                      <span className="cart-product-normal">
                        Adult {item.adult}
                      </span>
                      <br></br>
                      <span className="cart-product-normal">
                        Child {item.child}
                      </span>
                      <br></br>
                      <span className="cart-product-normal">
                        Add ON:{" "}
                        {Array.isArray(item.addOn) ? (
                          <>
                            {item.addOn.map((data) => {
                              return data.label + ", ";
                            })}
                          </>
                        ) : (
                          "N/A"
                        )}
                      </span>
                      <br></br>
                      <span className="cart-product-normal">
                        Transport {item.transport_type}
                      </span>
                      <br></br>
                      <span className="cart-product-normal">
                        Transport Charges AED {item.transport}
                      </span>
                      <br></br>
                      <span className="cart-product-normal">
                        Booking Date {item.date}
                      </span>
                      <br></br>
                      <br></br>
                      <Row>
                        <br></br>
                        <br></br>
                        <Col md={4}>
                          {/* <span className="cart-product-normal">
                            Update Booking
                          </span> */}
                          <span
                            className="cart-product-normal"
                            onClick={() => removeProduct(item.pid)}
                          >
                            Remove
                          </span>
                        </Col>
                        <Col md={4} className="text-center">
                          {/* <span className="cart-product-normal">Remove</span> */}
                        </Col>
                        <Col md={4}>
                          {/* <span className="cart-product-normal">
                            Move to wishlist
                          </span> */}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={2}>
                      <br></br>
                      <span className="cart-product-title">
                        AED{" "}
                        {calculateAmount(
                          item.adult,
                          item.child,
                          item.type.adult,
                          item.type.child,
                          item.transport,
                          item.addOn
                        )}
                        /-
                      </span>
                    </Col>
                    <Col md={2}></Col>
                  </Row>
                </Container>
              );
            })}
          </Col>
          <Col
            md={4}
            className="cart-payment-section"
            style={{ minHeight: "800px" }}
          >
            {/* <div
              id="mamo-checkout-element"
              data-src={payurl}
              style={{ height: "400px" }}
            /> */}
            <Container className="coupon_container">
              <div className="coupon_header">
                <h3 className="coupon_heading text-center">Apply Coupon</h3>
              </div>
              <div className="coupon_input_holder">
                <input
                  className="coupon_input"
                  placeholder="Coupon Code"
                  onChange={(e) => setCoupon(e.target.value)}
                />
                <Button
                  className="apply_coupon_button"
                  onClick={() => checkCoupon()}
                >
                  Apply
                </Button>
              </div>
            </Container>
            <Container className="cart_total_container">
              <div className="coupon_header">
                <h3 className="coupon_heading text-center">Cart Total</h3>
              </div>
              <Container>
                <BrowserView>
                  <Container>
                    <Row style={{ marginTop: "40px" }}>
                      <Col md={6} className="cart_total_div">
                        <span className="cart_total_text">Subtotal</span>
                      </Col>
                      <Col
                        md={6}
                        className="cart_total_div"
                        style={{ justifyContent: "end" }}
                      >
                        <span className="cart_total_text">
                          AED {getTotal()}
                        </span>
                      </Col>
                      <Col md={6} className="cart_total_div">
                        <span className="cart_total_text">Total</span>
                      </Col>
                      <Col
                        md={6}
                        className="cart_total_div"
                        style={{ justifyContent: "end" }}
                      >
                        <span className="cart_total_text">
                          AED {getTotal()}
                        </span>
                      </Col>
                      <Col md={6} className="cart_total_div">
                        <span className="cart_total_text">To Pay </span>
                      </Col>
                      <Col
                        md={6}
                        className="cart_total_div"
                        style={{ justifyContent: "end" }}
                      >
                        <span className="cart_total_text">
                          <strong>AED {getTotal()}</strong>
                        </span>
                      </Col>
                    </Row>
                    <Button
                      className="check_out_btn"
                      onClick={() => doCheckout()}
                    >
                      Checkout
                    </Button>
                  </Container>
                </BrowserView>
                <MobileView>
                  <Container>
                    <Row style={{ marginTop: "40px" }}>
                      <Col ms={6} className="cart_total_div">
                        <span className="cart_total_text">Subtotal</span>
                      </Col>
                      <Col
                        ms={6}
                        className="cart_total_div"
                        style={{ justifyContent: "end" }}
                      >
                        <span className="cart_total_text">
                          AED {getTotal()}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col ms={6} className="cart_total_div">
                        <span className="cart_total_text">Total</span>
                      </Col>
                      <Col
                        ms={6}
                        className="cart_total_div"
                        style={{ justifyContent: "end" }}
                      >
                        <span className="cart_total_text">
                          AED {getTotal()}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col ms={6} className="cart_total_div">
                        <span className="cart_total_text">To Pay </span>
                      </Col>
                      <Col
                        ms={6}
                        className="cart_total_div"
                        style={{ justifyContent: "end" }}
                      >
                        <span className="cart_total_text">
                          <strong>AED {getTotal()}</strong>
                        </span>
                      </Col>
                    </Row>
                    <Button
                      className="check_out_btn"
                      onClick={() => doCheckout()}
                    >
                      Checkout
                    </Button>
                  </Container>
                </MobileView>
              </Container>
            </Container>
          </Col>
        </Row>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Footer />
    </Container>
  );
}
