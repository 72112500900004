import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import "./index.css";
import help from "../../images/help.png";
import help_support from "../../images/help_support.png";
export default function Index() {
  return (
    <Container className="help-container">
      <div className="help-title-container">
        <h3 className="help-title text-center">Still have a question?</h3>
        <p className="help-description text-center">
          The Brilliant reasons Entrada should be your one-stop-shop!
        </p>
      </div>
      <Row>
        <Col md={2}></Col>
        <Col md={4} style={{ padding: "10px" }}>
          <div className="help-for-sales">
            <center>
              <Image src={help} className="help_icon" />
              <p className="help_text">
                For group booking (more than 15 persons) you can contact us
                below provided mail/contact number
              </p>
            </center>
          </div>
        </Col>

        <Col md={4} style={{ padding: "10px" }}>
          <div className="help-for-support">
            <center>
              <Image src={help_support} className="help_icon" />
              <p className="help_text">
                If you need any help or any assistance regarding booking or any
                support regarding location, please contact us on below
                mail/contact number.
              </p>
            </center>
          </div>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
}
